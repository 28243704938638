import Vue from 'vue'
import VueRouter from 'vue-router'
// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/homeView'
  },
  {
    path: '/homeView',
    name: 'homeView',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue'),
    children:[
      {
        path:"/homeView/home",  // 首页
        name:"home",
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
      },
      {
        path:"/homeView/service",  // 产品与服务
        name:"service",
        component: () => import(/* webpackChunkName: "service" */ '@/views/home/Service.vue'),
      },
      {
        path:"/homeView/case",  // 案例
        name:"case",
        component: () => import(/* webpackChunkName: "case" */ '@/views/home/Case.vue'),
      },
      {
        path:"/homeView/school",  // 运营学堂
        name:"school",
        component: () => import(/* webpackChunkName: "school" */ '@/views/home/School.vue'),
      },
      {
        path:"/homeView/aboutUs",  // 关于我们
        name:"aboutUs",
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/home/AboutUs.vue'),
      },
    ]
  },
  {
    path:"/schoolDetail/:id",
    name: 'schoolDetail',
    component: () => import(/* webpackChunkName: "schoolDetail" */ '@/views/SchoolDetail.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
