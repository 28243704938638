<template>
  <div id="app">
    <router-view />
    <SuspensionModal />
  </div>
</template>

<script>
import SuspensionModal from "@/components/pc/SuspensionModal.vue";
export default {
  components: { SuspensionModal },
};
</script>
<style lang="less">
@import "./assets/css/global.less";

html {
  font-size: 100px !important;
}
html,
body {
  width: 100%;
  min-height: 100vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #000;
  font-size: 0.14rem;
  width: 100%;
  min-height: 100vh;
  // background-color: pink;
}
</style>
