<template>
  <!-- 悬浮弹框 -->
  <div class="suspension_modal web_box" v-if="showSuspension">
    <div class="title">立即扫码咨询</div>
    <div class="qrcode_img">
      <img src="https://dance-saas-1300409620.cos.ap-guangzhou.myqcloud.com/official-website/assets/img/xiaoqiu_code.png" />
    </div>
    <div class="colse_img" @click="() => (showSuspension = false)">
      <img src="https://dance-saas-1300409620.cos.ap-guangzhou.myqcloud.com/official-website/assets/img/icon_close@2x.png" />
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      showSuspension: true,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.suspension_modal {
  width: 2.08rem;
  height: 2.4rem;
  padding: 0.2rem;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 6px 24px 0px rgba(3, 8, 82, 0.1);
  position: fixed;
  right: 0.6rem;
  bottom: 1.5rem;
  border-radius: 0.04rem;
  z-index: 10,
}
.title {
  text-align: center;
  color: #030852;
  font-weight: 600;
}
.qrcode_img {
  margin-top: 0.15rem;
  width: 1.68rem;
  height: 1.68rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.colse_img {
  width: 0.2rem;
  height: 0.2rem;
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  .web_box {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .mobile_box {
    display: none;
  }
}
</style>
